// React & Props
import React, { useRef, useEffect, useState } from "react";
import PropTypes from "prop-types";

import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";

// Lattice Components
import LatticeGrid from "lattice/lib/components/LatticeGrid";
import * as Breakpoints from "lattice/src/breakpoints.module.scss";

// Internal Components
import useOutsideClick from "../../hooks/useOutsideClick";
import useWindowSize from "../../hooks/useWindowSize";
// import useScreenOrientation from "../../hooks/useOrientation";

// import SEO from "../components/functional/seo";

function Modal({
  className,
  children,
  handleClose,
  hideOnDesktop,
  desktopWidth,
}) {
  const modalRef = useRef();
  const modalContentRef = useRef();
  const isIosDevice =
    typeof window !== "undefined" &&
    window.navigator &&
    window.navigator.platform &&
    (/iP(ad|hone|od)/.test(window.navigator.platform) ||
      (window.navigator.platform === "MacIntel" &&
        window.navigator.maxTouchPoints > 1));
  // const screenOrientation = useScreenOrientation();
  const { windowSize: size } = useWindowSize();
  const [allowOutsideClick, setAllowOutsideClick] = useState(false);
  const [returnScroll, setReturnScroll] = useState();
  if (
    hideOnDesktop &&
    size.width > parseInt(desktopWidth || Breakpoints.gridTablet, 10)
  ) {
    return null;
  }
  let modalOverlayClasses = ["shared__modal"];
  let modalClasses = ["shared__modal-clear"];
  // Add class if height is small to prevent locked display issues
  if (size && size.height <= parseInt(Breakpoints.gridMobileLarge, 10)) {
    modalOverlayClasses.push("shared__modal--overflow");
    modalClasses.push("shared__modal--overflow");
  }
  if (className) {
    modalOverlayClasses = modalOverlayClasses.concat(
      Array.isArray(className) ? className : className.split(" "),
    );
  }
  if (className) {
    modalClasses = modalClasses.concat(
      Array.isArray(className) ? className : className.split(" "),
    );
  }

  const onClose = () => {
    if (modalRef && modalRef.current) {
      // Return to scrollposition on IOS
      if (isIosDevice && window) {
        setTimeout(() => {
          window.scrollTo(0, returnScroll);
        }, 0);
      }
      enableBodyScroll(modalRef.current);
    }
    handleClose();
  };

  useOutsideClick(modalContentRef, () => {
    if (allowOutsideClick) {
      onClose();
    }
  });

  useEffect(() => {
    if (modalRef && modalRef.current) {
      // Save scroll position on IOS devices
      if (isIosDevice && window) {
        setReturnScroll(window.scrollY);
      }
      disableBodyScroll(modalRef.current);
    }
    return () => {
      if (modalRef && modalRef.current) {
        if (isIosDevice && window) {
          setTimeout(() => {
            window.scrollTo(0, returnScroll);
          }, 0);
        }
        return enableBodyScroll(modalRef.current);
      }
      return null;
    };
    // modalRef && modalRef.current ? enableBodyScroll(modalRef.current) : null;
  }, [modalRef]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setAllowOutsideClick(true);
    }, 200);
    return () => clearTimeout(timer);
  }, []);

  return (
    <>
      <LatticeGrid
        fullWidth
        overlay
        className={modalOverlayClasses.join(" ")}
        forwardRef={modalRef}
      />
      <LatticeGrid fullWidth overlay className={modalClasses.join(" ")}>
        <div className="shared__modal-grid">
          <div className="shared__modal-wrapper" ref={modalContentRef}>
            <div
              onClick={onClose}
              onKeyDown={onClose}
              className="shared__modal-close"
              role="button"
              tabIndex="0">
              Close
            </div>
            <div className="shared__modal-content">{children}</div>
          </div>
        </div>
      </LatticeGrid>
    </>
  );
}

Modal.designSystemProps = {
  children: {
    type: "node",
    description: "Modal content.",
    default: null,
    propType: PropTypes.node,
    required: false,
  },
  className: {
    type: ["string", "array"],
    description: "List of classes for this element.",
    required: false,
    default: "",
    propType: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.arrayOf(PropTypes.string),
    ]),
  },
  handleClose: {
    type: "function",
    description: "Event handler when modal is closed.",
    required: false,
    default: null,
    propType: PropTypes.func,
  },
  hideOnDesktop: {
    type: "boolean",
    description: "Will hide modal on desktop devices and up if true",
    propType: PropTypes.bool,
    required: false,
    default: false,
  },
  desktopWidth: {
    type: "number",
    description: "Width in pixels of minimum desktop breakpoint",
    propType: PropTypes.number,
    default: null,
    required: false,
  },
};

const propTypes = {};
const defaultProps = {};
Object.entries(Modal.designSystemProps).map(([k, v]) => {
  if (v.propType) {
    propTypes[k] = v.propType;
  }
  if (v.default || typeof v.default !== "undefined") {
    defaultProps[k] = v.default;
  }
  return false;
});
Modal.propTypes = { ...propTypes };
Modal.defaultProps = { ...defaultProps };

Modal.displayName = "Modal";

export default Modal;
