import React, { useState } from "react";
import loadable from "@loadable/component";
// import LatticeGrid from "lattice/lib/components/LatticeGrid";
import SEO from "../components/functional/seo";
import DesignStory from "../components/molecules/design-story";
import DesignProps from "../components/molecules/design-props";
import Modal from "../components/organisms/modal";
import Button from "../components/atoms/button";
// import CodeBlock from "../components/atoms/code-block";
const CodeBlock = loadable(() => import("../components/atoms/code-block"));

function DesignModal() {
  const [activeModal, setActiveModal] = useState(false);
  return (
    <div className="design__content">
      <SEO title="Design System - Modals" />
      <h2>Modals</h2>
      <p className="design__text p2">
        Modals are for displaying content overlaid the current page. The main
        use cases for modals are for displaying forms or expanding content from
        the referring page. Modals open from a button, link or icon, and can be
        closed via an icon, or clicking outside of the main content window.
      </p>

      {activeModal ? (
        <Modal
          handleClose={() => setActiveModal(false)}
          className="design__modal">
          <h4>Modal Title</h4>
          <p className="p3">Modal Content</p>
        </Modal>
      ) : null}

      <div className="design__stories">
        <DesignStory
          type="component"
          hideDisplay
          component={Modal}
          className="design__modal-transparent"
          props={{
            handleClose: () => setActiveModal(false),
          }}>
          Modal Content Goes Here
        </DesignStory>

        <div className="design__story-section">
          <h5>Open Modal From Button</h5>
          <DesignStory
            type="component"
            component={Button}
            className="shared__button--spacing"
            props={{
              color: "purple",
              onClick: (e) => {
                e.preventDefault();
                setActiveModal(true);
              },
            }}>
            Click To Open Modal
          </DesignStory>

          <p className="design__text p2">
            This example assumes that you&apos;ve set values to manage the modal
            state:
          </p>
          <CodeBlock language="jsx">
            {` const [activeModal, setActiveModal] = useState(false);`}
          </CodeBlock>
        </div>

        <div className="design__story-section">
          <DesignProps component={Modal} title="Modal Properties" />
        </div>
      </div>
    </div>
  );
}

export default DesignModal;
